export default {
    inject: {
        theme: {
            from: 'theme',
            default: () => ({ default: true })
        },
        themeIsTextDark: {
            from: 'themeIsTextDark',
            default: () => null,
        }
    }
}
