/** Manages listener removal on destroy */
let listeners = []
export default {
    methods: {
        addEventListener(element, event, executor, options = null) {
            listeners.push({
                element,
                event,
                executor,
                options,
            })
            element.addEventListener(event, executor, options)
        },
        removeEventListener(el, evt) {
            if (listeners.length === 0) return
            listeners = listeners.filter(
                ({ element, event, executor, options }) => {
                    if (el == element && evt == event) {
                        element.removeEventListener(event, executor, options)
                        return false
                    }
                    return true
                }
            )
        },
    },
    beforeDestroy() {
        for (const { element, event, executor, options } of listeners) {
            element.removeEventListener(event, executor, options)
        }
        listeners = []
    },
}

