
import eventListener from "@/mixins/eventListener"
import themeable from "@/mixins/themeable"
import intersectionObserver from "@/mixins/intersectionObserver"
import { mdiVideo, mdiPlayCircleOutline } from "@mdi/js"

const THUMBNAIL_FORMATS = [
    {
        width: 420,
    },
    {
        width: 960,
        media: "(min-width: 420px)",
    },
    {
        width: 1280,
        media: "(min-width: 960px)",
    },
    {
        width: 1920,
        media: "(min-width: 1200px)",
    },
]

export default {
    name: "ResponsiveVimeo",
    mixins: [eventListener, themeable, intersectionObserver],
    props: {
        /** Video object */
        video: {
            type: Object,
            required: true,
        },
        /** Mute the video */
        muted: {
            type: Boolean,
            default: false,
        },
        /** Autoplay the video after rendering */
        autoplay: {
            type: Boolean,
            default: false,
        },
        /** Play the video endlessly in a loop manner */
        loop: {
            type: Boolean,
            default: false,
        },
        /** if true, mobile devices will play the video inline */
        playsinline: {
            type: Boolean,
            default: false,
        },
        /** preload configuration for the video */
        preload: {
            type: String,
            default: "auto",
            // "metadata" loads poster image
            validator: (val) => ["auto", "metadata", "none"].includes(val),
        },
        /** Add controls to the video */
        controls: {
            type: Boolean,
            default: false,
        },
        /** Number of seconds before video is reset */
        resetTime: {
            type: Number,
            default: null,
            validator: (val) => val > 0,
        },
        /** Stopwatch type */
        timestamp: {
            type: String,
            default: null,
            validator: (val) =>
                ["static", "dynamic", "dynamic--reverse", "bar"].includes(val),
        },
        /** Forces to show only placeholder */
        forcePlaceholder: {
            type: Boolean,
            default: false,
        },
        /** Forces to play video, despite slow connections */
        forcePlaying: {
            type: Boolean,
            default: false,
        },
        /** Loads thumbnail eager */
        eager: {
            type: Boolean,
            default: false,
        },
        /** Enables no sound tooltip when video is NOT muted and playing */
        enableSoundTooltip: {
            type: Boolean,
            default: false,
        },
        /** Hides buffering animation */
        noLoadingAnimation: {
            type: Boolean,
            default: false,
        },
        /** Hides play button on slow connection */
        noPlayBtn: {
            type: Boolean,
            default: false,
        },
        /** Sets max width for placeholder */
        placeholderMaxWidth: {
            type: Number,
            default: undefined,
        },
    },
    emits: ["ready", "play-error"],
    data: () => ({
        timerCount: null,
        currentTime: "0:00",
        currentProgressBar: 0,
        timeUpdater: null,
        isStarted: false,
        isBuffering: false,
        THUMBNAIL_FORMATS,
        isReady: false,
        hasAudio: false,
        showTooltip: false, // no sound tooltip
        showTooltipTimeout: null,
    }),
    icons: {
        mdiVideo,
        mdiPlayCircleOutline,
    },
    computed: {
        thumbnailUrl() {
            if (this.placeholderMaxWidth) {
                const placeholderHeight = Math.round(
                    (this.placeholderMaxWidth * this.videoHeight) /
                        this.videoWidth
                )
                return (
                    this.video.thumbnail_url +
                    `_${this.placeholderMaxWidth}x${placeholderHeight}`
                )
            }
            return this.video.thumbnail_url
        },
        /** To disable video autoplay, autoplay="false" will not work; the video will autoplay if the attribute is there in the <video> tag at all. To remove autoplay, the attribute needs to be removed altogether. */
        shouldAutoplay() {
            if (this.isSlowMode) return false
            return this.autoplay && ["auto", "metadata"].includes(this.preload)
        },
        /** Using local file */
        isLocal() {
            return !!this.video?.local
        },
        showVideo() {
            if (this.forcePlaceholder) return false
            return this.video.is_rendered || this.isLocal
        },
        videoFile() {
            if (this.video.files)
                return this.video.files.reduce(this.compareRendition, -Infinity)
            console.error(
                "ERROR handling video: video.files is not available, expected array:",
                this.video
            )
            return null
        },
        videoWidth() {
            if (this.videoFile?.width) return this.videoFile.width
            return this.video.width
        },
        videoHeight() {
            if (this.videoFile?.height) return this.videoFile.height
            return this.video.height
        },
        videoSrc() {
            if (this.isLocal) return URL.createObjectURL(this.video.local)
            if (this.videoFile) return `${this.videoFile.link}#t=0.1`
            return null
        },
        videoSrcLowRes() {
            if (this.isLocal) return null
            return this.video?.files
                ?.filter((file) => file.rendition === "540p")
                ?.at(0)?.link
        },
        videoSrcMidRes() {
            if (this.isLocal) return null
            return this.video?.files
                ?.filter((file) => file.rendition === "720p")
                ?.at(0)?.link
        },
        videoSrcHighRes() {
            if (this.isLocal) return null
            return this.video?.files
                ?.filter((file) => file.rendition === "1080p")
                ?.at(0)?.link
        },
        videoSrcDefault() {
            if (this.isLocal) return this.videoSrc
            return this.videoSrcHighRes || this.videoSrc
        },
        videoSrcScaled() {
            if (this.isLocal) return this.videoSrc
            if (this.$breakpoint.xsOnly || this.$speed.isSlow === true) {
                return (
                    this.videoSrcLowRes ||
                    this.videoSrcMidRes ||
                    this.videoSrcDefault
                )
            } else if (this.$breakpoint.mdAndDown) {
                return this.videoSrcMidRes || this.videoSrcDefault
            }
            return this.videoSrcDefault
        },
        videoType() {
            if (this.isLocal) return this.video.local.type
            if (this.videoFile) return this.videoFile.type
            return null
        },
        timeUpdateListener() {
            if (!this.timestamp || this.timestamp === "static") return {}
            return { timeupdate: this.updateTime }
        },
        isSlowMode() {
            if (this.isLocal) return false
            return this.$speed.isSlow !== false && !this.forcePlaying
        },
        clickListener() {
            if (this.isSlowMode && this.autoplay && !this.isStarted) {
                return {
                    click: (e) => {
                        e.stopPropagation()
                        this.playVideo(true)
                    },
                }
            }
            return {}
        },
        aspectRatioCSS() {
            const aspectRatio = this.videoWidth / this.videoHeight
            /** aspectRation needs to be a string, otherwise it will be ignored on SSR */
            return {
                aspectRatio: aspectRatio.toString(),
            }
        },
    },
    watch: {
        timerCount: {
            handler(newVal) {
                if (newVal > 0) {
                    setTimeout(() => {
                        this.timerCount--
                    }, 1000)
                }
                if (newVal === 0) this.$refs.player.currentTime = 0
            },
            immediate: true, // ensures the watcher is triggered upon creation
        },
        resetTime(newVal) {
            if (newVal !== null) this.timerCount = newVal
        },
        muted(newVal) {
            if (!newVal && !this.hasAudio) {
                this.showTooltip = true
                if (this.showTooltipTimeout) {
                    clearTimeout(this.showTooltipTimeout)
                }
                this.showTooltipTimeout = setTimeout(
                    () => (this.showTooltip = false),
                    3000
                )
            }
        },
    },
    created() {
        if (this.timestamp === "dynamic--reverse")
            this.currentTime = this.toTimeFormat(this.video.duration)
    },
    mounted() {
        const player = this.$refs.player
        if (player) {
            if (this.preload !== "auto") {
                this.observeIntersection(
                    player,
                    () => {
                        if (this.autoplay) this.playVideo()
                        this.setReady()
                        this.unobserveAll()
                    },
                    null
                )
            }
            if (this.autoplay && !this.isSlowMode) {
                this.isStarted = true
                this.isBuffering = this.$refs.player?.readyState <= 2
            }

            // Video is ready to play
            if (player?.readyState === 4) {
                if (this.autoplay && this.muted) this.playVideo()
                this.setReady()
            }
        }
        // @timeupdate gives not enough frequency, therefore setInterval is used
        // On the other hand, setInterval performs redundant computations for other types
        // of stopwatch (e.g. when video is paused)
        if (this.timestamp === "bar")
            this.timeUpdater = setInterval(this.updateProgressBar, 100)
    },
    beforeDestroy() {
        if (this.timestamp === "bar") clearInterval(this.updateProgressBar)
    },
    methods: {
        setReady() {
            if (this.isReady) return
            this.isReady = true
            this.$nextTick(() => {
                /** Update audio state */
                this.hasAudio = this.getAudioState()
                this.$emit("ready", { hasAudio: this.hasAudio })
            })
        },
        getScaledThumbnailUrl(width) {
            const placeholderHeight = Math.round(
                (width * this.videoHeight) / this.videoWidth
            )
            return this.video.thumbnail_url + `_${width}x${placeholderHeight}`
        },
        onPlay() {
            this.isBuffering = false
            this.isStarted = true
        },
        /** Updates time for "dynamic", "dynamic--reverse" stopwatches */
        updateTime() {
            const time = this.$refs.player?.currentTime
            const duration = this.video.duration
            if (this.timestamp === "dynamic") {
                this.currentTime = this.toTimeFormat(time)
            } else if (this.timestamp === "dynamic--reverse") {
                this.currentTime = this.toTimeFormat(duration - time)
            }
        },
        /** Updates time for "bar" stopwatch */
        updateProgressBar() {
            const time = this.$refs.player?.currentTime
            const duration = this.$refs.player?.duration
            this.currentProgressBar = (100 * time) / duration
        },
        toTimeFormat(seconds) {
            const div = Math.trunc(Math.round(seconds) / 60)
            const rem = Math.round(seconds) % 60
            return `${div}:${rem >= 10 ? "" : "0"}${rem}`
        },
        /** Rendition comparator to compare two renditions in ascending order
         *  @param {String} elA rendition 1 of the video, e.g. 1080p
         *  @param {String} elB rendition 2 of the video, e.g. 720p
         *  @return {String} returns the maximum among the values
         * */
        compareRendition(elA, elB) {
            const renA = parseInt(elA.rendition)
                const renB = parseInt(elB.rendition)
            if (isNaN(renA)) return elB
            else if (isNaN(renB)) return elA
            else if (renA > renB) return elA
            return elB
        },
        /** Sets the video player at the beginning
         * Intended for call from outside the component */
        resetVideo() {
            this.timerCount = 0
            this.$refs.player.currentTime = 0
        },
        /** Continues to play video
         * Intended for call from outside the component */
        playVideo(force = false) {
            if (!force && this.isSlowMode === true && !this.forcePlaying) return
            if (this.forcePlaceholder) return
            this.$refs.player?.play().catch((e) => {
                this.$emit("play-error")
                console.warn("Error with vimeo-player@play", e)
            })
            this.isStarted = true
        },
        /** Pauses the video
         * * Intended for call from outside the component */
        pauseVideo() {
            this.$refs.player?.pause()
            if (this.resetTime !== null) this.timerCount = this.resetTime
        },
        /** Checks if audio is included */
        getAudioState() {
            const video = this.$refs?.player
            if (!video) return false
            return (
                video.mozHasAudio ||
                !!video.webkitAudioDecodedByteCount ||
                (!!video.audioTracks && video.audioTracks.length)
            )
        },
        onCanplay() {
                        this.setReady()

        },
    },
}
