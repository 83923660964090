
/**
 * https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/components/VProgressCircular/VProgressCircular.ts
 *
 * Mostly, a copy of the Vuetify component.
 */
import { Intersect } from "vuetify/lib/directives"
import {
    VFadeTransition,
    VSlideXTransition,
} from "vuetify/lib/components/transitions"

export default {
    name: "ProgressLinear",
    directives: { Intersect },

    props: {
        /** Reduced height */
        small: {
            type: Boolean,
            default: false,
        },
        /** Rounded borders */
        rounded: {
            type: Boolean,
            default: false,
        },
        /** Color of the progress bar, can be either a gradient or a pure color */
        color: {
            type: [String, Array],
            default: () => ["#3643ef", "#9400d1"],
        },
        /** If the progress bar is used on a dark background */
        dark: {
            type: Boolean,
            default: false,
        },
        /** Loading animation */
        indeterminate: {
            type: Boolean,
            default: false,
        },
        /** Transparent background */
        transparent: {
            type: Boolean,
            default: false,
        },
        /** Value (percentage 0..100) */
        value: {
            type: [Number, String],
            default: 0,
        },
        /** Sets the minimum possible value to be displayed (when the value is 0 nothing is displayed)  */
        minValue: {
            type: Number,
            default: 0,
        },
        /** value to display */
        displayedValue: {
            type: [Number, Boolean],
            default: false,
        },
    },

    data() {
        return {
            isVisible: true,
        }
    },
    computed: {
        height() {
            return this.small ? 4 : 20
        },
        background() {
            if (typeof this.color === "string") return this.color
            return `linear-gradient(to right, ${this.color.join(", ")})`
        },
        classes() {
            return {
                "progress-linear--reactive": this.reactive,
                "progress-linear--rounded": this.rounded,
                "progress-linear--visible": this.isVisible,
                "background-dark": this.dark && !this.transparent,
                "background-light": !this.dark && !this.transparent,
                "background-transparent": this.transparent,
            }
        },
        computedTransition() {
            return this.indeterminate ? VFadeTransition : VSlideXTransition
        },
        normalizedValue() {
            return this.normalize(this.value)
        },
        reactive() {
            return Boolean(this.$listeners.change)
        },
        radiusOfRounded() {
            return this.height / 2
        },
        displacementCoefficient() {
            return 1 - Math.abs(this.normalizedValue - 50) / 50
        },
        determinateWidth() {
            if (this.rounded) {
                return `calc(${
                    this.radiusOfRounded * this.displacementCoefficient
                }px + ${this.convertToUnit(this.normalizedValue, "%")})`
            } else return this.convertToUnit(this.normalizedValue, "%")
        },
    },
    methods: {
        convertToUnit(str, unit = "px") {
            if (str == null || str === "") {
                return undefined
            } else if (isNaN(+str)) {
                return String(str)
            } else {
                return `${Number(str)}${unit}`
            }
        },
        genBar() {
            return this.$createElement(this.computedTransition, [
                this.genBarType(),
            ])
        },
        genBarType() {
            return this.indeterminate
                ? this.genIndeterminate()
                : this.genDeterminate()
        },
        genNumber() {
            return this.displayedValue === false
                ? null
                : this.$createElement(
                      "span",
                      {
                          staticClass:
                              "progress-linear__number text-caption font-weight-bold pl-2",
                          style: {
                              left: this.determinateWidth,
                              top: this.small ? "-6px" : "2px",
                          },
                      },
                      `${this.displayedValue}`
                  )
        },
        genDeterminate() {
            return this.$createElement("div", {
                staticClass: `progress-linear__determinate`,
                style: {
                    background: this.background,
                    width: this.determinateWidth,
                },
                class: {
                    "progress-linear--rounded": this.rounded,
                },
            })
        },
        genIndeterminate() {
            return this.$createElement(
                "div",
                {
                    staticClass:
                        "progress-linear__indeterminate progress-linear__indeterminate--active",
                },
                [this.genProgressBar("long"), this.genProgressBar("short")]
            )
        },
        genProgressBar(name) {
            return this.$createElement("div", {
                staticClass: "progress-linear__indeterminate",
                style: {
                    background: this.background,
                },
                class: {
                    [name]: true,
                    "progress-linear--rounded": this.rounded,
                },
            })
        },
        onObserve(entries, observer, isIntersecting) {
            this.isVisible = isIntersecting
        },
        normalize(value) {
            if (value <= this.minValue) return this.minValue
            if (value > 100) return 100
            return parseFloat(value)
        },
        genProgressLinear() {
            const data = {
                staticClass: "progress-linear ",
                attrs: {
                    role: "progressbar",
                    "aria-label": "Fortschritt",
                    "aria-valuemin": 0,
                    "aria-valuemax": 100,
                    "aria-valuenow": this.indeterminate
                        ? undefined
                        : this.normalizedValue,
                },
                class: this.classes,
                directives: [
                    {
                        name: "intersect",
                        value: this.onObserve,
                    },
                ],
                style: {
                    height: this.convertToUnit(this.height),
                },
                on: this.$listeners,
            }
            return this.$createElement("div", data, [this.genBar()])
        },
    },

    render(h) {
        return h(
            "div",
            {
                staticClass: "progress-linear__wrapper",
            },
            [this.genProgressLinear(), this.genNumber()]
        )
    },
}
