import { render, staticRenderFns } from "./ResponsiveVimeo.vue?vue&type=template&id=498cf62c&scoped=true&"
import script from "./ResponsiveVimeo.vue?vue&type=script&lang=js&"
export * from "./ResponsiveVimeo.vue?vue&type=script&lang=js&"
import style0 from "./ResponsiveVimeo.vue?vue&type=style&index=0&id=498cf62c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "498cf62c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Picture: require('/builds/oabat/einblick/web/src/components/oabatify/Picture.vue').default,TransitionFade: require('/builds/oabat/einblick/web/src/components/oabatify/transition/TransitionFade.vue').default,ProgressLinear: require('/builds/oabat/einblick/web/src/components/oabatify/ProgressLinear.vue').default,ProgressCircular: require('/builds/oabat/einblick/web/src/components/oabatify/ProgressCircular.vue').default,Icon: require('/builds/oabat/einblick/web/src/components/oabatify/Icon.vue').default})
